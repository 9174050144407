import React from "react";
import { useTranslation } from "react-i18next";

import { ControlledProps } from "../index";
import { Input, Indicator, Text, Label } from "./styles";

export type ToggleInputProps = {
  inputProps?: {
    disabled?: boolean;
  };
} & ControlledProps<HTMLInputElement>;

const Toggle: React.FC<ToggleInputProps> = React.forwardRef(
  ({ inputProps, value, ...props }: ToggleInputProps, ref) => {
    const { t } = useTranslation();

    return (
      <Label value={!!value} disabled={inputProps?.disabled}>
        <Input {...inputProps} {...props} ref={ref} type="checkbox" />
        <Indicator />
        <Text>{value ? t("buttons:on") : t("buttons:off")}</Text>
      </Label>
    );
  },
);

export default Toggle;
