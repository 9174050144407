import styled from "styled-components";

import ContentWrapper from "components/ContentWrapper";

export const Container = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Content = styled(ContentWrapper)`
  justify-content: center;
`;

export const Title = styled.p`
  font-size: 22px;
  font-weight: 400;
  text-align: center;

  margin-bottom: 24px;
`;
