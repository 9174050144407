import React from "react";
import { Content } from "./styles";

type Props = {
  children: React.ReactNode;
  alignCenter?: boolean;
  className?: string;
};

const ContentWrapper: React.FC<Props> = ({
  children,
  alignCenter,
  className,
}) => (
  <Content alignCenter={alignCenter} className={className}>
    {children}
  </Content>
);

export default ContentWrapper;
