import React from "react";
import { useTranslation } from "react-i18next";

import { Header, Description } from "../styles";
import { ListItem, ListTitle, ListWrapper } from "./styles";

import { getApplicationName } from "utils";
import { useImageContext } from "components/ImageContext";

const AccessPage: React.FC = () => {
  const { t } = useTranslation();
  const resource = useImageContext();

  const application = getApplicationName();

  return (
    <>
      <Header>{t("accessPage:header", { application })}</Header>
      <Description>{t("accessPage:description", { application })}</Description>
      <ListTitle>{t("accessPage:listTitle")}</ListTitle>
      <ListWrapper>
        <ListItem iconUrl={resource.lock}>
          {t("accessPage:willNotHaveAccess")}
        </ListItem>
        <ListItem iconUrl={resource.unlink}>
          {t("accessPage:canDisable")}
        </ListItem>
      </ListWrapper>
    </>
  );
};

export default AccessPage;
