import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { unflatten } from "flat";

import AccessPage from "./AccessPage";
import Footer from "components/Footer";
import Form from "components/Form";
import ContentWrapper from "components/ContentWrapper";
import PageIndicators from "../PageIndicators";
import PermissionsPage from "./PermissionsPage";
import TermsPage from "./TermsPage";

import { getDisplayedScopes } from "services/identity";
import { getScopes } from "utils";

import { PageWrapper } from "./styles";

const PAGES = [
  <AccessPage key="AccessPage" />,
  <TermsPage key="TermsPage" />,
  <PermissionsPage key="PermissionsPage" />,
];

const PAGE_NUMBERS = new Array(PAGES.length)
  .fill(undefined)
  .map((val, idx) => idx);

type Props = {
  submitForm: (data: any) => void;
  isButtonsDisabled: boolean;
};

const Pages: React.FC<Props> = ({ submitForm, isButtonsDisabled }) => {
  const { t } = useTranslation();
  const BUTTON_TEXT = useMemo(
    () => [t("buttons:next"), t("buttons:agree"), t("buttons:submit")],
    [t],
  );

  const [currentPage, setCurrentPage] = useState(0);
  const clickNext = useCallback(() => {
    if (currentPage < PAGES.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage]);
  const clickBack = useCallback(() => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage]);

  const scopes = getScopes();

  const displayedScopes = useMemo(() => getDisplayedScopes(scopes), [scopes]);

  return (
    <Form
      onSubmit={submitForm}
      options={{
        defaultValues: {
          scopesConsented: unflatten(
            Object.fromEntries(displayedScopes.map(scope => [scope, true])),
          ),
          rememberConsent: true,
        },
      }}
    >
      <ContentWrapper>
        <PageIndicators pageNumbers={PAGE_NUMBERS} currentPage={currentPage} />
        <PageWrapper>{PAGES[currentPage]}</PageWrapper>
      </ContentWrapper>
      <Footer
        clickNext={clickNext}
        clickBack={clickBack}
        buttonText={BUTTON_TEXT[currentPage]}
        showBackButton={!!currentPage}
        buttonType={
          currentPage === PAGE_NUMBERS.length - 1 ? "submit" : "button"
        }
        isDisabled={isButtonsDisabled}
      />
    </Form>
  );
};

export default Pages;
