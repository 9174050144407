import styled, { css } from "styled-components";

import GlobeIconSvg from "assets/globe.svg";
import DoneIconSvg from "assets/done.svg";

import { COLORS } from "utils/constants";

export const Icon = styled.div`
  width: 20px;
  height: 20px;

  background-size: contain;
  background-image: url(${GlobeIconSvg});

  margin-right: 6px;
`;

export const Button = styled.button<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;

  background-color: ${({ isSelected }) =>
    isSelected ? COLORS.PALE_HIGHLIGHT : COLORS.WHITE};
  border: none;

  margin: 16px 32px;
  padding: 8px;

  &:hover {
    background-color: ${COLORS.PALE_HIGHLIGHT};
  }
`;

export const PopoverContainer = styled.div`
  background-color: white;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  border: 1px solid ${COLORS.GRAY_BORDER};

  width: 128px;
  padding: 8px 0;
`;

export const PopoverOption = styled.div<{ isSelected?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 8px;

  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected ? COLORS.PALE_HIGHLIGHT : COLORS.WHITE};

  &:hover {
    background-color: ${COLORS.PALE_HIGHLIGHT};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      &::before {
        content: "";

        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: 4px;
        background-image: url(${DoneIconSvg});

        width: 30%;
        height: 24px;
      }
    `}
`;

export const PopoverOptionText = styled.span`
  width: 70%;
`;
