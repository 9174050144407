import React from "react";
import {
  RegisterOptions,
  useController,
  useFormContext,
} from "react-hook-form";

import { ControlledProps } from "components/Input";

type AdditionalProps<T> = { inputProps?: T };

type RegistrableInputProps<T> = { name: string } & AdditionalProps<T>;

type InComponent<T, U> = React.ComponentType<
  ControlledProps<T> & AdditionalProps<U>
>;

type OutComponent<T> = React.ComponentType<
  RegistrableInputProps<T> & {
    rules?: Exclude<
      RegisterOptions,
      "valueAsNumber" | "valueAsDate" | "setValueAs"
    >;
  }
>;

type Props<T, U> = {
  name: string;
  inputProps: U;
  rules?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  Component: InComponent<T, U>;
};

function RegisteredInput<T, U>({
  name,
  rules,
  inputProps,
  Component,
}: Props<T, U>): React.ReactElement<RegistrableInputProps<U>> {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    control,
    rules,
  });
  return (
    <Component {...field} inputProps={inputProps} error={fieldState.error} />
  );
}

export function registerInput<T, U>(
  Component: InComponent<T, U>,
): OutComponent<U> {
  return ({ name, inputProps, rules }) => (
    <RegisteredInput
      name={name}
      rules={rules}
      inputProps={inputProps}
      Component={Component}
    />
  );
}
