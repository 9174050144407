import styled from "styled-components";
import { BREACKPOINT, COLORS } from "utils/constants";

type Props = {
  value: boolean;
  disabled?: boolean;
};

export const Label = styled.label<Props>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 70px;
  min-width: 70px;
  height: 30px;
  border-radius: 17.5px;
  padding: 4px;
  flex-direction: ${props => (props.value ? "row-reverse" : "row")};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? "0.3" : "1")};
  background-color: ${props => (props.value ? COLORS.GREEN : COLORS.GREY)};
  margin: 6px 20px;

  @media (${BREACKPOINT.DOWN_420}) {
    margin: 6px 16px;
  }
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

export const Indicator = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: white;
`;

export const Text = styled.span`
  margin: 0 8px;
  font-size: 12px;
  letter-spacing: 0.08px;
  font-weight: 600;
  user-select: none;
  pointer-events: none;
  color: white;
`;
