import styled from "styled-components";
import { BREACKPOINT, COLORS } from "utils/constants";

type Props = {
  isActive?: boolean;
  isLast?: boolean;
};

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 25px;
  @media (${BREACKPOINT.DOWN_420}) {
    margin-top: 26px;
    margin-bottom: 26px;
  }
`;

export const Indicator = styled.div<Props>`
  max-width: 284px;
  min-width: 105px;
  margin-right: ${props => (props.isLast ? "0" : "24px")};
  height: ${props => (props.isActive ? "8px" : "4px")};
  border-radius: ${props => (props.isActive ? "5px" : "3px")};
  background-color: ${props =>
    props.isActive ? COLORS.ORANGE_INDICATOR : COLORS.BLACK};
  opacity: ${props => (props.isActive ? "1" : "0.15")};
  flex-grow: 1;
  @media (${BREACKPOINT.DOWN_420}) {
    margin-right: ${props => (props.isLast ? "0" : "10px")};
  }
`;
