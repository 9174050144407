import styled from "styled-components";
import ContentWrapper from "components/ContentWrapper";

export const Container = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LoaderContainer = styled(ContentWrapper).attrs({
  alignCenter: true,
})`
  justify-content: center;
`;
