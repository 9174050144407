import React from "react";
import ReactDOM from "react-dom";

import "promise-polyfill/src/polyfill";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "./index.css";
import App from "./entry";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
