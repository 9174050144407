import styled from "styled-components";

import Switcher from "components/LanguageSwitcher";

import { COLORS, SIZES } from "utils/constants";

export const Container = styled.div`
  height: ${SIZES.HEADER};
  border-bottom: 1px solid ${COLORS.GREY};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LanguageSwitcher = styled(Switcher)`
  position: absolute;
  right: 0;
  top: 0;
`;
