import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import ContentWrapper from "components/ContentWrapper";
import Footer from "components/Footer";
import { Description, Prompt, ConfirmationIcon, UnableIcon } from "./styles";
// import { getApplicationName } from "utils";

export enum RESULT {
  OK = "ok",
  ERROR = "error",
}

type Props = {
  url: string;
  result: RESULT;
  okTitle: string;
  okDescription?: string;
  errorTitle: string;
  errorDescription?: string;
  hideFooter?: boolean;
};

const Confirmation: React.FC<Props> = ({
  url,
  result,
  okTitle,
  okDescription,
  errorTitle,
  errorDescription,
  hideFooter,
}: Props) => {
  const { t } = useTranslation();

  const onClose = useCallback(() => {
    window.location.href = url;
  }, [url]);

  return (
    <>
      <ContentWrapper alignCenter>
        {result === RESULT.OK ? <ConfirmationIcon /> : <UnableIcon />}
        <Description>{result === RESULT.OK ? okTitle : errorTitle}</Description>
        {result === RESULT.OK && okDescription && (
          <Description>{okDescription}</Description>
        )}
        {result === RESULT.ERROR && errorDescription && (
          <Description>{errorDescription}</Description>
        )}
        {!hideFooter && <Prompt>{t("confirmation:prompt")}</Prompt>}
      </ContentWrapper>
      {!hideFooter && (
        <Footer
          clickNext={onClose}
          buttonText={t("buttons:close")}
          buttonType="button"
        />
      )}
    </>
  );
};

export default Confirmation;
