import styled from "styled-components";

type Props = {
  iconUrl?: string;
};

export const ListTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 26px 0;
`;

export const ListWrapper = styled.ul`
  && {
    padding-left: 21px;
  }
`;

export const ListItem = styled.li<Props>`
  font-size: 14px;
  font-weight: 400;
  list-style-type: none;
  position: relative;
  margin-bottom: 14px;

  &:before {
    content: "";
    position: absolute;
    left: -21px;
    top: 4px;
    width: 14px;
    height: 14px;
    background-image: ${props => `url(${props.iconUrl})`};
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center;
  }
`;
