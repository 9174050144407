import React, { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Logo from "assets/logo.svg";
import { login } from "services/identity";
import { parseQueryString } from "utils";

import { LoginFormContent } from "./LoginFormContent";

import {
  Form,
  Container,
  LogoText,
  Header,
  ErrorContainer,
  LanguageSwitcher,
} from "./styles";

const Login: React.FC = () => {
  const { ReturnUrl } = parseQueryString();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const onSubmit = useCallback(
    async data => {
      setLoading(true);
      setError(false);
      try {
        await login({ ...data, ReturnUrl });
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [ReturnUrl],
  );

  return (
    <Container>
      <img alt="MCNA Dental" src={Logo} width={240} height={86} />
      <LanguageSwitcher />
      <LogoText>{t("login:logoText")}</LogoText>
      <Header>{t("login:header")}</Header>
      {isError && (
        <ErrorContainer>
          <div>{t("login:oops")}</div>
          <div>{t("login:tryAgain")}</div>
        </ErrorContainer>
      )}
      <Form
        onSubmit={onSubmit}
        options={{
          reValidateMode: "onChange",
          mode: "onChange",
          defaultValues: { Username: "", Password: "" },
        }}
      >
        <LoginFormContent isLoading={isLoading} />
      </Form>
    </Container>
  );
};

export default withRouter(Login);
