import { applyIf } from "utils";
import { apiHost } from "utils/env";

const API_URL = apiHost;

type Options = {
  omitUrlPrefix?: boolean;
};

type Request = {
  url: string;
  options?: Options;
};

type RequestWithBody = {
  data: Record<string, any>;
} & Request;

const getFullUrl = (url: string) => `${API_URL}${url}`;

const getUrl = (url: string, omitUrlPrefix?: boolean) =>
  applyIf(!omitUrlPrefix, getFullUrl)(url);

export const post = ({
  url,
  data,
  options: { omitUrlPrefix } = {},
}: RequestWithBody) =>
  fetch(getUrl(url, omitUrlPrefix), {
    method: "POST",
    credentials: "include",
    redirect: "follow",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

export const get = ({ url, options: { omitUrlPrefix } = {} }: Request) =>
  fetch(getUrl(url, omitUrlPrefix), {
    method: "GET",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
