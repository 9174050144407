import React, { useState, useEffect, useContext } from "react";
import { IMAGES_NAMES } from "utils/constants";

type ImagesUrl = {
  [name in IMAGES_NAMES]: string;
};

const Context = React.createContext<Partial<ImagesUrl>>({});

type Props = {
  imagesArray: { name: IMAGES_NAMES; src: string }[];
  children: React.ReactNode;
};

const ImageContext: React.FC<Props> = ({ children, imagesArray }) => {
  const [icons, setIcons] = useState<Partial<ImagesUrl>>({});
  useEffect(() => {
    let icons: any = {};
    imagesArray.forEach(icon => {
      let newIcon = new Image();
      newIcon.src = icon.src;
      icons[icon.name] = newIcon.src;
    });
    setIcons(icons);
  }, []);

  return <Context.Provider value={icons}>{children}</Context.Provider>;
};

export const useImageContext = () => useContext(Context);

export default ImageContext;
