import styled from "styled-components";

export const SubHeader = styled.p`
  font-size: 14px;
  margin: 26px 0;
  font-family: "OpenSans-Bold";
`;

export const Indent = styled.p`
  font-size: 14px;
  margin: 26px 0 0 0;
`;
