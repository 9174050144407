import React from "react";
import "i18n";

import Navigation from "navigation";
import ImageContext from "components/ImageContext";

import Profile from "assets/profile.svg";
import Clipboard from "assets/clipboard.svg";
import User from "assets/user.svg";
import Lock from "assets/lock.svg";
import Unlink from "assets/unlink.svg";
import { IMAGES_NAMES } from "utils/constants";

const IconsArray = [
  { name: IMAGES_NAMES.PROFILE, src: Profile },
  { name: IMAGES_NAMES.CLIPBOARD, src: Clipboard },
  { name: IMAGES_NAMES.USER, src: User },
  { name: IMAGES_NAMES.LOCK, src: Lock },
  { name: IMAGES_NAMES.UNLINK, src: Unlink },
];

const App = () => (
  <ImageContext imagesArray={IconsArray}>
    <Navigation />
  </ImageContext>
);

export default App;
