import React, { useCallback, useMemo, useState } from "react";
import { Popover } from "react-tiny-popover";
import { useTranslation } from "react-i18next";

import { saveLocale } from "i18n";
import { LOCALES } from "utils/constants";

import {
  Button,
  Icon,
  PopoverContainer,
  PopoverOption,
  PopoverOptionText,
} from "./styles";

type Props = {
  className?: string;
};

const LanguageSwitcher: React.FC<Props> = ({ className }: Props) => {
  const [isOpen, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const handleClickOutside = useCallback(() => {
    setOpen(false);
  }, []);

  const { t, i18n } = useTranslation();

  const handleLanguageSelect = useMemo(
    () => (locale: string) => () => {
      saveLocale(locale);
      i18n.changeLanguage(locale);
      setOpen(false);
    },
    [i18n],
  );

  return (
    <Popover
      isOpen={isOpen}
      positions={["bottom"]}
      content={
        <PopoverContainer>
          {[LOCALES.EN, LOCALES.ES].map(locale => (
            <PopoverOption
              key={locale}
              isSelected={locale === i18n.language}
              onClick={handleLanguageSelect(locale)}
            >
              <PopoverOptionText>{t(`languages:${locale}`)}</PopoverOptionText>
            </PopoverOption>
          ))}
        </PopoverContainer>
      }
      onClickOutside={handleClickOutside}
      align="end"
    >
      <Button className={className} onClick={handleClick} isSelected={isOpen}>
        <Icon />
        <div>{i18n.language.toUpperCase()}</div>
      </Button>
    </Popover>
  );
};

export default LanguageSwitcher;
