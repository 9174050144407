import styled from "styled-components";
import { SIZES } from "utils/constants";

type Props = {
  alignCenter?: boolean;
};

export const Content = styled.div<Props>`
  height: calc(100vh - ${SIZES.FOOTER} - ${SIZES.HEADER});
  max-width: 900px;
  min-width: 335px;
  width: 100vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: ${props => (props.alignCenter ? "center" : "initial")};
  overflow: auto;
`;
