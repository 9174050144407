export const jsonOrReject = (response: Response) =>
  response.ok ? response.json() : Promise.reject();

export const urlOrReject = (response: Response) => {
  if (!response.ok) return Promise.reject();

  return response.url;
};

export const fieldOrReject = (field: string) => (data: Record<string, any>) =>
  (data || {})[field] || Promise.reject();
