import React, { ChangeEventHandler, FocusEventHandler } from "react";

import { Container, Description, Header } from "./styles";
import Toggle from "components/Form/Input/Toggle";
import ToggleInput from "components/Input/Toggle";

type WrapperProps = {
  header: string;
  description: string;
  iconUrl?: string;
  disabled?: boolean;
};

type WrapperWithChildrenProps = {
  children: React.ReactNode;
} & WrapperProps;

type ToggleProps = {
  name: string;
} & WrapperProps;

type SimpleProps = {
  name: string;
  value: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
} & WrapperProps;

const ToggleWrapper: React.FC<WrapperWithChildrenProps> = ({
  header,
  description,
  iconUrl,
  children,
}) => (
  <Container iconUrl={iconUrl}>
    <div>
      <Header>{header}</Header>
      <Description>{description}</Description>
    </div>
    {children}
  </Container>
);

const ToggleFormContainer: React.FC<ToggleProps> = ({
  header,
  description,
  iconUrl,
  disabled,
  name,
}) => {
  return (
    <ToggleWrapper header={header} description={description} iconUrl={iconUrl}>
      <Toggle name={name} inputProps={{ disabled }} />
    </ToggleWrapper>
  );
};

export default ToggleFormContainer;

export const ToggleSimpleContainer: React.FC<SimpleProps> = React.forwardRef(
  ({ header, description, iconUrl, disabled, ...props }, ref) => {
    return (
      <ToggleWrapper
        header={header}
        description={description}
        iconUrl={iconUrl}
      >
        <ToggleInput
          inputProps={{ disabled }}
          ref={ref as React.Ref<HTMLInputElement>}
          {...props}
        />
      </ToggleWrapper>
    );
  },
);
