import React from "react";
import { Button, Container } from "./styles";
import { useTranslation } from "react-i18next";

type Props = {
  clickNext: () => void;
  clickBack?: () => void;
  buttonText: string;
  showBackButton?: boolean;
  buttonType: "submit" | "button";
  isDisabled?: boolean;
};

const Footer: React.FC<Props> = ({
  clickNext,
  clickBack,
  buttonText,
  showBackButton,
  buttonType,
  isDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      {showBackButton && (
        <Button back onClick={clickBack} type="button" disabled={isDisabled}>
          {t("buttons:back")}
        </Button>
      )}
      <Button
        key={buttonType}
        onClick={clickNext}
        type={buttonType}
        disabled={isDisabled}
      >
        {buttonText}
      </Button>
    </Container>
  );
};

export default Footer;
