import React from "react";

import { ControlledProps } from "../index";
import { Input, Label, Checkmark } from "./styles";

export type ToggleInputProps = {
  inputProps?: {
    label: string;
  };
} & ControlledProps<HTMLInputElement>;

const Checkbox: React.FC<ToggleInputProps> = React.forwardRef(
  ({ inputProps, name, value, ...props }: ToggleInputProps, ref) => {
    return (
      <Label>
        {inputProps?.label}
        <Input
          {...inputProps}
          {...props}
          value={value}
          ref={ref}
          type="checkbox"
          id={name}
          name={name}
        />

        <Checkmark value={value} />
      </Label>
    );
  },
);

export default Checkbox;
