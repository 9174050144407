import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import { parseQueryString } from "utils";
import { logout } from "services/identity";
import ROUTES from "navigation/routes";

import Confirmation, { RESULT } from "components/Confirmation";
import { Button } from "components/Footer/styles";
import Header from "components/Header";

import { Container, Content, Title } from "./styles";

const Logout: React.FC = () => {
  const { t } = useTranslation();
  const [result, setResult] = useState<RESULT | null>(null);
  const [url, setUrl] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(false);

  const { logoutId } = parseQueryString<{ logoutId: string }>();

  const onLogout = useCallback(async () => {
    setLoading(true);
    try {
      const { postLogoutRedirectUri } = await logout({ logoutId });
      setUrl(postLogoutRedirectUri);
      setResult(RESULT.OK);
    } catch {
      setResult(RESULT.ERROR);
    } finally {
      setLoading(false);
    }
  }, [logoutId]);

  if (!logoutId)
    return <Redirect to={`${ROUTES.LOGIN}${window.location.search}`} />;

  return (
    <Container>
      <Header />
      {result ? (
        <Confirmation
          url={url || "/"}
          result={result}
          okTitle={t("logout:ok")}
          errorTitle={t("logout:unable")}
        />
      ) : (
        <Content alignCenter={true}>
          <Title>{t("logout:wouldYou")}</Title>
          <Button type="button" disabled={isLoading} onClick={onLogout}>
            {t("logout:logout")}
          </Button>
        </Content>
      )}
    </Container>
  );
};

export default Logout;
