import React from "react";
import { Container, Indicator } from "./styles";

type Props = {
  pageNumbers: number[];
  currentPage: number;
};

const PageIndicators: React.FC<Props> = ({ pageNumbers, currentPage }) => {
  return (
    <Container>
      {pageNumbers.map((number, idx) => (
        <Indicator
          key={`${number}`}
          isActive={number === currentPage}
          isLast={idx === pageNumbers.length - 1}
        />
      ))}
    </Container>
  );
};

export default PageIndicators;
