import styled from "styled-components";
import { BREACKPOINT } from "utils/constants";

type Props = {
  iconUrl?: string;
};

export const Container = styled.div<Props>`
  width: 100%;
  border: 1px solid #ced1d5;
  display: flex;
  justify-content: space-between;
  align-items: top;
  padding: 16px 0 16px 66px;
  border-radius: 6px;
  position: relative;
  margin-bottom: 20px;

  &:before {
    content: "";
    position: absolute;

    top: 22px;
    left: 20px;
    width: 26px;
    height: 30px;
    background-image: ${props => `url(${props.iconUrl})`};
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center;

    @media (${BREACKPOINT.DOWN_420}) {
      left: 16px;
    }
  }

  @media (${BREACKPOINT.DOWN_420}) {
    padding-left: 56px;
  }
`;

export const Header = styled.p`
  font-size: 14px;
  margin-bottom: 6px;
  font-family: "OpenSans-SemiBold";
`;

export const Description = styled.p`
  font-size: 14px;
`;
