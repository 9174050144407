import styled, { css } from "styled-components";
import ConfirmationIconSvg from "assets/confirmation.svg";
import UnableIconSvg from "assets/unable.svg";

const IconStyle = css`
  width: 106px;
  height: 106px;
  background-size: contain;
  margin: 66px 0 30px 0;
`;

export const ConfirmationIcon = styled.div`
  ${IconStyle};
  background-image: url(${ConfirmationIconSvg});
`;

export const UnableIcon = styled.div`
  ${IconStyle};
  background-image: url(${UnableIconSvg});
`;

export const Description = styled.p`
  font-size: 22px;
  font-weight: 400;
  text-align: center;
`;

export const Prompt = styled.p`
  font-size: 14px;
  margin-top: 12px;
`;
