import * as queryString from "query-string";
import { ParsedQuery } from "query-string";

import { OPTIONAL_SCOPES } from "services/identity";

export const parseQueryString = <T extends ParsedQuery>(value?: string): T =>
  queryString.parse(value || window.location.search) as T;

export const getApplicationName = (): string => {
  const { returnUrl } = parseQueryString();
  const url = returnUrl as string;
  if (!url) return "";
  const query = url.slice(url.indexOf("?") + 1);
  const { client_id } = parseQueryString(query);
  return client_id as string;
};

export const getScopes = (): OPTIONAL_SCOPES[] => {
  const { returnUrl } = parseQueryString();
  const { scope } = parseQueryString(returnUrl as string);
  return ((scope as string | null) || "").split(" ") as OPTIONAL_SCOPES[];
};

export const applyIf =
  <T>(condition: boolean | undefined, fn: (...args: T[]) => T) =>
  (...args: T[]) =>
    condition ? fn(...args) : args[0];

export const entries = <K extends string, V>(obj: { [key in K]?: V }) =>
  Object.entries(obj) as [K, V][];
