export enum BREACKPOINT {
  DOWN_420 = "max-width: 420px",
}

export enum COLORS {
  WHITE = "#ffffff",
  BLACK = "#000000",
  GREY = "#B5B5B5",
  PALE_HIGHLIGHT = "#f5f6f6",
  GRAY_TEXT = "#727577;",
  GRAY_BORDER = "#dbe3f3",
  ORANGE_BUTTON = "#FFA600",
  ORANGE_BUTTON_DISABLED = "#fac97d",
  BLUE_BUTTON = "#0264b1",
  BLUE_BUTTON_DISABLED = "rgba(29, 154, 252, 0.5)",
  ORANGE_INDICATOR = "#F5A623",
  TEXT = "#24272A",
  GREEN = "#4AA564",
  BLUE = "#243e5e",
  BLUE_BORDER = "#207ac1",
  GREY_BORDER = "#CED1D5",
  RED = "#890011",
}

export enum SIZES {
  HEADER = "80px",
  FOOTER = "80px",
}

export enum IMAGES_NAMES {
  PROFILE = "profile",
  CLIPBOARD = "clipboard",
  USER = "user",
  LOCK = "lock",
  UNLINK = "unlink",
}

export const LOCALES = {
  EN: "en",
  ES: "es",
};
