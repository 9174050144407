const APP_ENV_FIELD = "REACT_APP_ENV";
const API_FIELD = "REACT_APP_API_HOST";

export const ENVS = {
  DEVELOPMENT: "dev",
  QA: "qa",
  PRODUCTION: "production",
};

export const environmentName = process.env[APP_ENV_FIELD];
export const apiHost = process.env[API_FIELD];