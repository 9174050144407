import styled from "styled-components";
import { COLORS } from "utils/constants";
import Eye from "assets/eye.svg";
import EyeSlash from "assets/eye-slash.svg";

type ButtonProps = {
  isVisible?: boolean;
};

type InputProps = {
  isPassword?: boolean;
  isError: boolean;
};

export const Container = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

export const ErrorMessage = styled.span`
  color: ${COLORS.RED};
  font-size: 14px;
  padding-left: 26px;
  padding-top: 4px;
`;

export const EyeButton = styled.button<ButtonProps>`
  position: absolute;
  width: 20px;
  height: 20px;
  border: none;
  right: 18px;
  top: 18px;
  background-color: transparent;
  background-image: url(${props => (props.isVisible ? EyeSlash : Eye)});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;

export const Label = styled.label`
  position: absolute;
  background-color: ${COLORS.WHITE};
  top: -9px;
  left: 24px;
  font-size: 12px;
  color: ${COLORS.BLUE_BORDER};
  padding: 0 4px;
  display: none;
`;

export const Input = styled.input<InputProps>`
  border: 1px solid
    ${props => (props.isError ? COLORS.RED : COLORS.GREY_BORDER)};
  border-radius: 25px;
  padding: 16px 26px;
  width: 100%;
  font-size: 14px;

  padding-right: ${props => (props.isPassword ? "52px" : "26px")};

  &:focus {
    border: 1px solid ${COLORS.BLUE_BORDER};
    outline: none;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:focus + ${Label} {
    display: block;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }
`;
