import styled from "styled-components";
import { COLORS } from "utils/constants";

type Props = {
  value?: boolean;
};

export const Label = styled.label`
  font-size: 14px;
  display: block;
  position: relative;
  padding-left: 27px;
  cursor: pointer;
  width: fit-content;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const Checkmark = styled.span<Props>`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  box-sizing: border-box;
  background-color: ${props => (props.value ? COLORS.BLUE : COLORS.WHITE)};
  border-radius: 3px;
  border: ${props =>
    props.value
      ? `1px solid ${COLORS.BLUE}`
      : `1px solid ${COLORS.GREY_BORDER}`};

  &:after {
    content: "";
    position: absolute;
    display: ${props => (props.value ? "block" : "none")};

    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(35deg);
  }
`;
