import React, { useState } from "react";

import { ControlledProps } from "../index";
import { Input, Label, Container, EyeButton, ErrorMessage } from "./styles";

export type TextInputProps = {
  inputProps?: {
    type?: string;
    placeholder?: string;
  };
} & ControlledProps<HTMLInputElement>;

const TextInput: React.FC<TextInputProps> = React.forwardRef(
  ({ inputProps, name, error, ...props }: TextInputProps, ref) => {
    const isPassword = inputProps?.type === "password";
    const [isVisible, setIsVisible] = useState(!isPassword);
    const inputType = !isPassword
      ? inputProps?.type
      : isVisible
      ? "text"
      : "password";
    const onChangeVisible = () => {
      setIsVisible(!isVisible);
    };

    return (
      <Container>
        <Input
          {...inputProps}
          {...props}
          name={name}
          ref={ref}
          type={inputType}
          isPassword={isPassword}
          isError={!!error}
        />
        <Label htmlFor={name}>{inputProps?.placeholder}</Label>
        {isPassword && (
          <EyeButton
            onClick={onChangeVisible}
            type="button"
            isVisible={isVisible}
          />
        )}

        {error && <ErrorMessage role="alert">{error.message}</ErrorMessage>}
      </Container>
    );
  },
);

export default TextInput;
