import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import isEmail from "sane-email-validation";

import TextInput from "components/Form/Input/Text";

import { Button } from "./styles";

type Props = {
  isLoading: boolean;
};

export const LoginFormContent = ({ isLoading }: Props) => {
  const { t } = useTranslation();

  const {
    formState: { isValid },
    watch,
  } = useFormContext();

  watch("Username");
  watch("Password");

  return (
    <>
      <TextInput
        name="Username"
        rules={{
          required: `${t("errors:required")}`,
          validate: v => isEmail(v) || `${t("errors:validEmail")}`,
        }}
        inputProps={{ placeholder: t("login:emailPlaceholder") }}
      />
      <TextInput
        name="Password"
        rules={{ required: `${t("errors:required")}` }}
        inputProps={{
          type: "password",
          placeholder: t("login:passwordPlaceholder"),
        }}
      />
      <Button disabled={isLoading || !isValid}>{t("buttons:logIn")}</Button>
    </>
  );
};
