import styled from "styled-components";

export const Header = styled.h2`
  font-size: 28px;
  font-weight: 400;
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 26px 0;
`;

export const PageWrapper = styled.div``;
