import styled from "styled-components";

import DefaultForm from "components/Form";
import Switcher from "components/LanguageSwitcher";

import { BREACKPOINT, COLORS } from "utils/constants";

export const Form = styled(DefaultForm)`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 368px;
  width: 100vw;
  padding: 0 20px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 60px;
`;

export const Header = styled.h2`
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 20px;
`;

export const LogoText = styled.p`
  font-size: 24px;
  font-family: "OpenSans-Light";
  letter-spacing: 2.4px;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 60px;
`;

export const Button = styled.button`
  background-color: ${COLORS.BLUE_BUTTON};
  color: ${COLORS.WHITE};
  border: none;
  padding: 10px 0;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  min-width: 200px;
  margin-bottom: 20px;

  @media (${BREACKPOINT.DOWN_420}) {
    min-width: 100%;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
    background-color: ${COLORS.BLUE_BUTTON_DISABLED};
  }
`;

export const ErrorContainer = styled.div`
  width: 380px;
  margin-bottom: 20px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  font-size: 14px;

  & > div:first-child {
    font-family: OpenSans-SemiBold, serif;
  }

  & > div:last-child {
    color: ${COLORS.GRAY_TEXT};
  }
`;

export const LanguageSwitcher = styled(Switcher)`
  position: absolute;
  right: 0;
  top: 0;
`;
