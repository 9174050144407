import styled, { css } from "styled-components";
import React from "react";

type Styling = TemplateStringsArray[];

const Container = styled.div<{ styling: Styling }>`
  ${({ styling: [first, ...rest] }) => css(first, ...rest)}
`;

export function withStyledContainer<P>(Component: React.ComponentType<P>) {
  return (...styling: Styling) =>
    (props: P) =>
      (
        <Container styling={styling}>
          <Component {...props} />
        </Container>
      );
}
