import React, { useCallback, useState } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getApplicationName, parseQueryString } from "utils";
import ROUTES from "navigation/routes";
import { consent } from "services/identity";

import Header from "components/Header";
import Confirmation, { RESULT } from "components/Confirmation";
import Pages from "./Pages";
import { Wrapper } from "./styles";

const Permissions: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [result, setResult] = useState<RESULT | null>(null);

  const { returnUrl } = parseQueryString();

  const submitForm = useCallback(
    async data => {
      setLoading(true);
      try {
        await consent({ ...data, returnUrl });
        setResult(RESULT.OK);
      } catch {
        setResult(RESULT.ERROR);
      } finally {
        setLoading(false);
      }
    },
    [returnUrl],
  );

  const application = getApplicationName();

  if (!application)
    return <Redirect to={`${ROUTES.LOGIN}${window.location.search}`} />;

  return (
    <Wrapper>
      <Header />
      {result ? (
        <Confirmation
          url={returnUrl as string}
          result={result}
          okTitle={t("confirmation:saved")}
          okDescription={t("confirmation:access", { application })}
          errorTitle={t("confirmation:unable")}
        />
      ) : (
        <Pages submitForm={submitForm} isButtonsDisabled={isLoading} />
      )}
    </Wrapper>
  );
};

export default Permissions;
