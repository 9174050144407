import React from "react";
import { useTranslation } from "react-i18next";

import { Header } from "../styles";
import { Indent, SubHeader } from "./styles";

import { EN_TERMS } from "./EnglishTerms";
import { ES_TERMS } from "./SpanishTerms";
import { LOCALES } from "utils/constants";

const TERMS_BY_LOCALE = {
  [LOCALES.EN]: EN_TERMS,
  [LOCALES.ES]: ES_TERMS,
};

const TermsPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Header>{t("termsPage:header")}</Header>
      <SubHeader>{t("termsPage:lastUpdated")}</SubHeader>
      <Indent
        dangerouslySetInnerHTML={{ __html: TERMS_BY_LOCALE[i18n.language] }}
      />
      <Indent />
    </>
  );
};

export default TermsPage;
