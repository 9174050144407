import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
import es from "./es.json";
import { LOCALES } from "utils/constants";

const resources = {
  en,
  es,
};

const langKey = "locale";

const getLocale = () => localStorage.getItem(langKey);
export const saveLocale = (locale: string) => localStorage.setItem(langKey, locale);

i18n.use(initReactI18next).init({
  resources,
  lng: getLocale() || LOCALES.EN, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
