import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getApplicationName, getScopes } from "utils";
import { getDisplayedScopes } from "services/identity";
import { useImageContext } from "components/ImageContext";

import { Description, Header } from "../styles";
import { SubHeader, Checkbox } from "./styles";
import ToggleContainer, { ToggleSimpleContainer } from "./ToggleContainer";

const PermissionsPage: React.FC = () => {
  const { t } = useTranslation();
  const resource = useImageContext();

  const application = getApplicationName();
  const scopes = getScopes();

  const displayedScopes = useMemo(() => getDisplayedScopes(scopes), [scopes]);

  return (
    <>
      <Header>{t("permissionPage:header")}</Header>
      <Description>
        {t("permissionPage:description", { application })}
      </Description>

      <SubHeader>{t("permissionPage:personalInformation:header")}</SubHeader>
      <ToggleSimpleContainer
        name="scopesConsented.openid"
        header={t("permissionPage:personalInformation:identifierHeader")}
        description={t(
          "permissionPage:personalInformation:identifierDescription",
        )}
        iconUrl={resource.user}
        value={true}
        disabled
      />
      <SubHeader>{t("permissionPage:applicationAccess:header")}</SubHeader>
      {displayedScopes.map(scope => (
        <ToggleContainer
          key={scope}
          name={`scopesConsented.${scope}`}
          header={t(`permissionPage:applicationAccess:${scope}_header`)}
          description={t(
            `permissionPage:applicationAccess:${scope}_description`,
          )}
          iconUrl={resource.clipboard}
        />
      ))}
      <Checkbox
        name="rememberConsent"
        inputProps={{ label: t("permissionPage:remember") }}
      />
    </>
  );
};

export default PermissionsPage;
