import React, { useCallback, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { parseQueryString } from "utils";
import ROUTES from "navigation/routes";
import { getError } from "services/identity";

import Header from "components/Header";
import Confirmation, { RESULT } from "components/Confirmation";
import Spinner from "components/Spinner";

import { Container, LoaderContainer } from "./styles";

const Error: React.FC = () => {
  const { t } = useTranslation();

  const { errorId } = parseQueryString<{ errorId: string }>();
  const [url, setUrl] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(true);

  const onError = useCallback(async () => {
    setLoading(true);
    try {
      const { postLogoutRedirectUri, error, errorDescription } = await getError(
        { errorId },
      );
      setUrl(postLogoutRedirectUri);
      setTitle(error);
      setDescription(errorDescription);
    } catch {
      setTitle(null);
    } finally {
      setLoading(false);
    }
  }, [errorId]);

  useEffect(() => {
    if (errorId) {
      onError();
    }
  }, [onError, errorId]);

  if (!errorId)
    return <Redirect to={`${ROUTES.LOGIN}${window.location.search}`} />;

  return (
    <Container>
      <Header />
      {isLoading ? (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      ) : (
        <Confirmation
          url={url || "/"}
          result={RESULT.ERROR}
          okTitle={""}
          errorTitle={title || t("error:unknown")}
          errorDescription={description || ""}
          hideFooter
        />
      )}
    </Container>
  );
};

export default Error;
