import styled from "styled-components";
import { BREACKPOINT, COLORS, SIZES } from "utils/constants";

type Props = {
  back?: boolean;
};

export const Container = styled.div`
  height: ${SIZES.FOOTER};
  border-top: 1px solid ${COLORS.GREY};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button<Props>`
  background-color: ${props =>
    props.back ? COLORS.WHITE : COLORS.BLUE_BUTTON};
  color: ${props => (props.back ? COLORS.BLACK : COLORS.WHITE)};
  border: ${props => (props.back ? `1px solid ${COLORS.BLACK}` : "none")};
  padding: 10px 0;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  min-width: 160px;
  margin: 0 15px;

  @media (${BREACKPOINT.DOWN_420}) {
    margin: 0 10px;
    min-width: ${props => (props.back ? `140px` : "175px")};
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }
`;
