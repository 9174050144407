import React from "react";

import { Container, Svg, Circle } from "./styles";

type Props = {
  className?: string;
};

const Spinner: React.FC<Props> = ({ className }) => {
  return (
    <Container className={className}>
      <Svg viewBox="25 25 50 50">
        <Circle
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
      </Svg>
    </Container>
  );
};

export default Spinner;
