import React, { useCallback } from "react";
import {
  useForm,
  FormProvider,
  UseFormProps,
  SubmitHandler,
} from "react-hook-form";

type Props<T, U extends object> = {
  options?: UseFormProps<T, U>;
  onSubmit: (data: T) => void;
  children: React.ReactNode;
  className?: string;
};

function Form<T, U extends object>({
  options,
  onSubmit,
  children,
  className,
}: Props<T, U>): React.ReactElement {
  const methods = useForm<T, U>(options);

  const handleSubmit = useCallback<SubmitHandler<T>>(
    data => {
      onSubmit(data as T);
    },
    [onSubmit],
  );

  return (
    <FormProvider {...methods}>
      <form className={className} onSubmit={methods.handleSubmit(handleSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
}

export default Form;
