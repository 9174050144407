import React from "react";
import {
  Switch,
  Redirect,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";

import ROUTES from "./routes";

import Login from "pages/Login";
import Permissions from "pages/Permissions";
import Logout from "pages/Logout";
import Error from "pages/Error";

const Navigation: React.FC = () => (
  <Router>
    <Switch>
      <Route exact path={ROUTES.LOGIN} component={Login} />
      <Route exact path={ROUTES.PERMISSIONS} component={Permissions} />
      <Route exact path={ROUTES.LOGOUT} component={Logout} />
      <Route exact path={ROUTES.ERROR} component={Error} />
      <Redirect to={ROUTES.PERMISSIONS} />
    </Switch>
  </Router>
);

export default Navigation;
