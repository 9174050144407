import flatten from "flat";
import getByPath from "lodash.get";

import { get, post } from "./api";
import { fieldOrReject, jsonOrReject } from "utils/api";
import { entries } from "utils";

type LoginPayload = {
  Username: string;
  Password: string;
  ReturnUrl: string;
};

type LogoutPayload = {
  logoutId: string;
};

type ErrorPayload = {
  errorId: string;
};

// @ts-ignore
type Scope = boolean | Record<string, Scope>;

type ConsentData = {
  scopesConsented: Record<string, Scope>;
  rememberConsent?: boolean;
  returnUrl: string;
};

type ConsentPayload = {
  scopesConsented: Array<string>;
  rememberConsent: boolean;
  returnUrl: string;
};

export enum OPTIONAL_SCOPES {
  PATIENT_READ = "patient/*.read",
  LAUNCH_PATIENT = "launch/patient",
  OFFLINE_ACCESS = "offline_access",
  LAUNCH = "launch",
  USER_READ = "user/*.read",
}

enum REQUIRED_SCOPES {
  OPEN_ID = "openid",
  FHIR_USER = "fhirUser",
}

export const ALLOWED_OPTIONAL_SCOPES = [
  OPTIONAL_SCOPES.PATIENT_READ,
  OPTIONAL_SCOPES.LAUNCH_PATIENT,
  OPTIONAL_SCOPES.OFFLINE_ACCESS,
  OPTIONAL_SCOPES.LAUNCH,
  OPTIONAL_SCOPES.USER_READ,
];

export const GROUPED_SUB_SCOPES: {
  [key in OPTIONAL_SCOPES]?: Array<OPTIONAL_SCOPES>;
} = {
  [OPTIONAL_SCOPES.LAUNCH]: [OPTIONAL_SCOPES.USER_READ],
};

export const login = (data: LoginPayload) =>
  post({
    url: "/api/authenticate",
    data,
  })
    .then(jsonOrReject)
    .then(fieldOrReject("redirectUrl"))
    .then(url => {
      window.location.href = url;
    });

export const logout = ({ logoutId }: LogoutPayload) =>
  get({ url: `/api/authenticate/logout?logoutId=${logoutId}` }).then(
    jsonOrReject,
  );

export const getError = ({ errorId }: ErrorPayload) =>
  get({ url: `/api/authenticate/error?errorId=${errorId}` }).then(jsonOrReject);

const getConsentPayload = (data: ConsentData): ConsentPayload => {
  const optionalScopesSelected = Object.keys(
    flatten(data.scopesConsented),
  ).filter(key =>
    getByPath(data.scopesConsented, key, false),
  ) as OPTIONAL_SCOPES[];

  const scopesSelected: Array<REQUIRED_SCOPES | OPTIONAL_SCOPES> = [
    REQUIRED_SCOPES.OPEN_ID,
    REQUIRED_SCOPES.FHIR_USER,
    ...optionalScopesSelected,
  ];

  const scopesConsented = scopesSelected.concat(
    optionalScopesSelected
      .filter((scope: OPTIONAL_SCOPES) => GROUPED_SUB_SCOPES[scope])
      .map(scope => GROUPED_SUB_SCOPES[scope]!)
      .flat(),
  );

  return {
    scopesConsented,
    rememberConsent: !!data.rememberConsent,
    returnUrl: data.returnUrl,
  };
};

export const consent = (data: ConsentData): Promise<Response> =>
  post({ url: "/api/user-consents", data: getConsentPayload(data) });

export const getDisplayedScopes = (scopes: OPTIONAL_SCOPES[]) => {
  const allowedScopes = ALLOWED_OPTIONAL_SCOPES.filter(scope =>
    scopes.includes(scope),
  );
  return allowedScopes.filter(
    scope =>
      !entries(GROUPED_SUB_SCOPES).some(
        ([rootScope, subScopes]) =>
          allowedScopes.includes(rootScope) && subScopes.includes(scope),
      ),
  );
};
