import React from "react";

import Logo from "assets/logo.svg";
import { Container, LanguageSwitcher } from "./styles";

const Header: React.FC = () => {
  return (
    <Container>
      <img alt="MCNA Dental" src={Logo} width={140} height={50} />
      <LanguageSwitcher />
    </Container>
  );
};

export default Header;
